.ant-select {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  background-color: white !important;
  border-radius: 0.25rem;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  padding-bottom: 0.2rem !important;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 2px;
}