@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
    @layer responsive {
      .masonry-2-col {
        column-count: 2;
        column-gap: 2em;
      }
      .break-inside {
        break-inside: avoid;
      }
      .break-after{
      break-after: always;
      }
    }
  }

/* CSS variables. */
:root {
	--PhoneInput-color--focus: transparent;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
	--rs-bg-active: #000 !important;
	--rs-primary-100: #adadad !important;
	--rs-primary-700: #000 !important;
}

@layer base {
	:root {
	  --background: 0 0% 100%;
	  --foreground: 240 10% 3.9%;
	  --card: 0 0% 100%;
	  --card-foreground: 240 10% 3.9%;
	  --popover: 0 0% 100%;
	  --popover-foreground: 240 10% 3.9%;
	  --primary: 240 5.9% 10%;
	  --primary-foreground: 0 0% 98%;
	  --secondary: 240 4.8% 95.9%;
	  --secondary-foreground: 240 5.9% 10%;
	  --muted: 240 4.8% 95.9%;
	  --muted-foreground: 240 3.8% 46.1%;
	  --accent: 240 4.8% 95.9%;
	  --accent-foreground: 240 5.9% 10%;
	  --destructive: 0 84.2% 60.2%;
	  --destructive-foreground: 0 0% 98%;
	  --border: 240 5.9% 90%;
	  --input: 240 5.9% 90%;
	  --ring: 240 10% 3.9%;
	  --chart-1: 12 76% 61%;
	  --chart-2: 173 58% 39%;
	  --chart-3: 197 37% 24%;
	  --chart-4: 43 74% 66%;
	  --chart-5: 27 87% 67%;
	  --chart-6: 203 44% 93%;
	  --chart-7: 228 12% 48%;
	  --chart-8: 269 73% 55%;
	  --chart-9: 256 8% 90%;
	  --chart-10: 340 65% 47%;
	}
	.dark {
	  --background: 240 10% 3.9%;
	  --foreground: 0 0% 98%;
	  --card: 240 10% 3.9%;
	  --card-foreground: 0 0% 98%;
	  --popover: 240 10% 3.9%;
	  --popover-foreground: 0 0% 98%;
	  --primary: 0 0% 98%;
	  --primary-foreground: 240 5.9% 10%;
	  --secondary: 240 3.7% 15.9%;
	  --secondary-foreground: 0 0% 98%;
	  --muted: 240 3.7% 15.9%;
	  --muted-foreground: 240 5% 64.9%;
	  --accent: 240 3.7% 15.9%;
	  --accent-foreground: 0 0% 98%;
	  --destructive: 0 62.8% 30.6%;
	  --destructive-foreground: 0 0% 98%;
	  --border: 240 3.7% 15.9%;
	  --input: 240 3.7% 15.9%;
	  --ring: 240 4.9% 83.9%;
	  --chart-1: 220 70% 50%;
	  --chart-2: 160 60% 45%;
	  --chart-3: 30 80% 55%;
	  --chart-4: 280 65% 60%;
	  --chart-5: 340 75% 55%;
	  }
  }


.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
  border: 0;
  background-color: transparent;
  margin: 0 !important;
}

.PhoneInputInput:focus {
  outline: none !important;
  border: 0px !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.ant-drawer {
	z-index: 10000 !important;
}

.ag-header-cell-label {
	word-break: keep-all;
	word-wrap: break-word;
}

.tremor-BarList-barText {
	margin-bottom: 0px !important;
}

.rs-picker-popup{
	z-index: 99999 !important;
}


.rs-input{
	margin-bottom: 0px !important;
}

.rs-picker-error>.rs-picker-input-group, .rs-picker-error>.rs-picker-input-group:hover {
	border-color:#adadad !important;
}

@keyframes slideIn {
	from {
	  opacity: 0;
	  transform: translateX(-10px);
	}
	to {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  
  .animate-slide-in {
	animation: slideIn 0.3s ease-out forwards;
  }

  @keyframes highlight {
	0% {
	  background-color: rgba(255, 233, 147, 0.6);
	}
	100% {
	  background-color: transparent;
	}
  }  
  .animate-highlight {
	padding: 12px;
	animation: highlight 60s ease-out;
  }