.thin-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.thin-horizontal-scrollbar::-webkit-scrollbar {
  height: 2px;
}

.ant-select {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  background-color: white !important;
  border-radius: 0.25rem;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  padding-bottom: 0.2rem !important;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  font-size: 15px !important;
  font-weight: 600;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: black !important;
}

.ant-tag {
  background-color: #f0eee9 !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;
  margin: 0px !important;
}

.ant-select-selection-overflow {
  margin-bottom: -0.2rem !important;
  padding: 4px !important;
  display: flex !important;
  gap: 6px !important;
  flex-wrap: nowrap !important;
  overflow: auto !important;
}