.ag-theme-alpine .ag-root-wrapper {
  border-color: transparent !important;
  --ag-header-foreground-color: black;
  --ag-header-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-row-hover-color: transparent;
  --ag-selected-row-background-color: #d2d2d2;
}
.ag-center-cols-viewport {
  height: '100%' !important;
  overflow-x: auto !important;
}
.ag-ltr .ag-cell {
  border-right: 1px solid #e5e7eb !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 8px !important;
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: auto !important;
}

.ag-ltr .ag-cell {
  border-right: 1px solid rgba(46, 43, 46, 0.2) !important;
}

.ag-header-cell {
  font-size: 16px !important;
  cursor: pointer !important;
  font-weight: 600 !important;
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
