.ant-modal-content {
  border-radius: 8px !important;
  height: 100% !important;
}

.ant-modal-body {
  height: 100% !important;
}

.ant-modal-footer {
  display: none !important;
  visibility: hidden !important;
}

.ant-modal-close-x {
  display: none !important;
  visibility: hidden !important;
}