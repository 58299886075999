/* General form styles */
form {
  width: 300px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  padding: 0 !important;
  box-shadow: none !important;
}

form label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
}

form input[type="text"],
form input[type="email"],
form input[type="submit"],
form .g-recaptcha {
  display: block;
  width: 100%;
  /* padding: 10px; */
  margin-bottom: 15px;
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
  box-sizing: border-box;
  font-size: 14px;
}

form input[type="radio"] {
  margin-right: 5px;
}

form div {
  margin-bottom: 15px;
}

form input[type="submit"] {
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s;
}

form input[type="submit"]:hover {
  background-color: #333;
}

/* Ensure alignment for the radio button group */
form div input[type="radio"]+label {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}

/* General radio input styling */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #6c757d;
  /* Default border color */
  border-radius: 50%;
  /* Circle shape */
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-weight: 200;
  margin: 0;
}

input[type="radio"]:checked {
  border-color: #007bff;
  /* Change border color when checked */
  background-color: #007bff;
  /* Fill color when checked */
}

input[type="radio"]:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

/* Styling the radio labels */
input[type="radio"]+label {
  margin-left: 8px;
  font-size: 16px;
  color: #212529;
  /* Text color */
  /* cursor: pointer; */
  /* user-select: none; */
}

input[type="radio"]:hover {
  border-color: #0056b3;
  /* Border color on hover */
}

input[type="radio"]:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  /* Outline on focus */
}

/* Layout for radio groups */
.radio-group {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 4px;
}

.radio-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 4px;
  margin-top: 8px;
}

.radio-label {
  font-weight: 400;
}