.thin-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.hide-scrollbar::-webkit-scrollbar {
  height: 0px;
}

/* @media (prefers-color-scheme: dark) {
  .thin-scrollbar::-webkit-scrollbar-track {
    background: #71717a;
  }

  .thin-scrollbar::-webkit-scrollbar-thumb {
    background: #ffffff;
  }

  .hide-scrollbar::-webkit-scrollbar-track {
    background: #71717a;
  }

  .hide-scrollbar::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
} */